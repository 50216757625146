import * as Yup from 'yup';
import { useRef, useState, useEffect } from 'react';
import { TextField, Avatar, Stack, Button } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import { useFormik, Form, FormikProvider } from 'formik';
import { ref, uploadBytesResumable, getDownloadURL, getStorage } from 'firebase/storage';
import { setDoc, doc, query, collection, where, getDocs, updateDoc } from 'firebase/firestore';
import loadingImage from '../../../assest/loading.gif';
import { db, localUser } from '../../../App';
import './style.css';
import { Link as RouterLink } from 'react-router-dom';
import getAndUpdateDoc from '../FaciltiyMembers/getAndUpdateDoc';
import UpdateNameInEveryCollection from './UpdateNameInEveryCollection';
import { cloudFuncBaseUrl } from 'src/utils/config';
import axios from 'axios';

function ProfileCard() {
  const inputFile = useRef(null);
  const [refs, loaded, onLoad] = useImageLoaded();
  const user = JSON.parse(localStorage.getItem('Rel8User'));
  const [image, setImage] = useState();
  const [previewImage, setPreviewImage] = useState(
    user.avatarUrl || '/static/mock-images/avatars/avatar_default.jpg'
  );
  // const [email, setEmail] = useState(user.email);
  const [loading, setIsLoading] = useState(false);

  const RegisterSchema = Yup.object().shape({
    firstname: Yup.string().trim().required('First name is required')
    // lastname: Yup.string().trim().required('Last name is required')
  });
  const formik = useFormik({
    initialValues: {
      firstname: user?.firstname,
      lastname: user?.lastname,
      email: user?.email,
      organisation: user?.organization ?? '',
      jobTitle: user?.jobTitle ?? ''
    },
    validationSchema: RegisterSchema,
    onSubmit: async (values) => {
      const { email, firstname, lastname, organisation, jobTitle } = values;
      if (!previewImage && !image) {
        return NotificationManager.error('Image is required');
      }

      try {
        setIsLoading(true);

        let data = {
          id: localUser?.id,
          email,
          firstname: values.firstname.trim(),
          lastname: values.lastname.trim(),
          role: localUser?.role
        };

        if (image) {
          console.log('image !!!!!!!');
          const storage = getStorage();
          const storageRef = ref(storage, `users/${user.id}/profile`);
          const metadata = {
            contentType: 'image/jpeg'
          };
          const uploadTask = await uploadBytesResumable(storageRef, image, metadata);

          const avatarUrl = await getDownloadURL(uploadTask.ref);

          if (user.role === 'Facility Member') {
            data.organisation = organisation.trim();
            data.jobTitle = jobTitle.trim();
            data.avatarUrl = avatarUrl;
            data.subRole = localUser?.subRole;
            data.residentIds = localUser?.residentIds;
            data.facilityIds = localUser?.facilityIds;
            //
            user.organisation = values.organisation.trim();
            user.jobTitle = values.jobTitle.trim();
            //
          } else if (user.role === 'Family Member') {
            data.jobTitle = '';
            data.avatarUrl = avatarUrl;
            data.subRole = localUser?.subRole;
            data.residentIds = localUser?.residentIds;
            data.facilityIds = localUser?.facilityIds;
          } else if (user.role === 'admin') {
            data.avatarUrl = avatarUrl;
            data.subRole = null;
          }

          // await setDoc(doc(db, 'users', user.id), data, { merge: true });

          // await UpdateNameInEveryCollection(`${firstname} ${lastname}`);

          user.email = email;
          user.firstname = values.firstname.trim();
          user.lastname = values.lastname.trim();
          user.avatarUrl = avatarUrl;
          localStorage.setItem('Rel8User', JSON.stringify(user));

          const updatedUser = await axios({
            method: 'post',
            url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
            data
          });
          console.log(updatedUser?.data);

          setIsLoading(false);
          await NotificationManager.success('Profile updated successfully');
          return window.location.reload(false);
          // window.location.href = '/';
        }
        console.log('no image');

        if (user.role === 'Facility Member') {
          data.organisation = organisation.trim();
          data.jobTitle = jobTitle.trim();
          data.avatarUrl = localUser.avatarUrl ?? '';
          data.subRole = localUser?.subRole;
          data.residentIds = localUser?.residentIds;
          data.facilityIds = localUser?.facilityIds;
        } else if (user.role === 'Family Member') {
          data.jobTitle = '';
          data.avatarUrl = localUser.avatarUrl ?? '';
          data.subRole = localUser?.subRole;
          data.residentIds = localUser?.residentIds;
          data.facilityIds = localUser?.facilityIds;
        } else if (user.role === 'admin') {
          data.avatarUrl = localUser.avatarUrl ?? '';
          data.subRole = null;
        }

        const updatedUser = await axios({
          method: 'post',
          url: `${cloudFuncBaseUrl}.cloudfunctions.net/updateUserEveryWhere`,
          data
        });

        console.log('response !! update user', updatedUser);

        // await setDoc(doc(db, 'users', user.id), data, { merge: true });
        // // if (firstname !== user?.firstname || lastname !== user?.lastname) {
        // await UpdateNameInEveryCollection(`${firstname} ${lastname}`);
        // }

        user.email = email;
        user.firstname = firstname;
        user.lastname = lastname;
        localStorage.setItem('Rel8User', JSON.stringify(user));
        setIsLoading(false);
        await NotificationManager.success('Profile updated successfully');
        return window.location.reload(false);
        // window.location.href = '/';
      } catch (error) {
        setIsLoading(false);
        return NotificationManager.error(`Error ! ${error.message}`);
      }
    }
  });
  const {
    errors,
    touched,
    handleSubmit,
    isSubmitting,
    getFieldProps,
    setFieldValue,
    setFieldTouched
  } = formik;
  return (
    <div className="clearfix">
      <div className="row">
        <div className="col-md-4 animated fadeIn">
          <div className="card">
            <div className="card-body">
              <Avatar
                className="avatar"
                sx={{ height: '150px', width: '150px', cursor: 'pointer' }}
                src={previewImage}
                // ref={refs}
                // onLoad={onLoad}
                onClick={() => inputFile.current.click()}
              />
              <input
                ref={inputFile}
                accept="image/*"
                type="file"
                id="file"
                onChange={(e) => {
                  setImage(e.target.files[0]);
                  setPreviewImage(URL.createObjectURL(e.target.files[0]));
                }}
                style={{ display: 'none' }}
              />

              <br />
              <FormikProvider value={formik}>
                <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
                  <NotificationContainer />
                  <Stack spacing={3} sx={{ alignItems: 'center' }}>
                    <TextField
                      sx={{ width: '90%' }}
                      label="First Name"
                      {...getFieldProps('firstname')}
                      error={Boolean(touched.firstname && errors.firstname)}
                      helperText={touched.firstname && errors.firstname}
                    />
                    <TextField
                      sx={{ width: '90%' }}
                      label="Last Name"
                      {...getFieldProps('lastname')}
                      error={Boolean(touched.lastname && errors.lastname)}
                      helperText={touched.lastname && errors.lastname}
                    />

                    {localUser?.role === 'Facility Member' && (
                      <>
                        <TextField
                          disabled={user.role !== 'Facility Member' && true}
                          sx={{ width: '90%' }}
                          label="Organisation"
                          {...getFieldProps('organisation')}
                          error={Boolean(touched.organisation && errors.organisation)}
                          helperText={touched.organisation && errors.organisation}
                        />
                        <TextField
                          disabled={user.role !== 'Facility Member' && true}
                          sx={{ width: '90%' }}
                          label="Job Title"
                          {...getFieldProps('jobTitle')}
                          error={Boolean(touched.jobTitle && errors.jobTitle)}
                          helperText={touched.jobTitle && errors.jobTitle}
                        />
                      </>
                    )}
                    <TextField
                      sx={{ width: '90%' }}
                      disabled
                      label="Email"
                      {...getFieldProps('email')}
                      error={Boolean(touched.email && errors.email)}
                      helperText={touched.email && errors.email}
                    />
                    {/* <LoadingButton
                      // fullWidth
                      component={RouterLink}
                      to="/home/change-password"
                      state={{ id: user?.id, email: user?.email, type: 'admin' }}
                      sx={{ width: '90%' }}
                      size="large"
                      type="submit"
                      variant="outlined"
                      loading={loading}
                    >
                      Update Password
                    </LoadingButton> */}
                    <LoadingButton
                      // fullWidth
                      sx={{ width: '90%' }}
                      size="large"
                      type="submit"
                      variant="contained"
                      loading={loading}
                    >
                      Update Profile
                    </LoadingButton>
                  </Stack>
                </Form>
              </FormikProvider>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileCard;

const useImageLoaded = () => {
  const [loaded, setLoaded] = useState(false);
  const refs = useRef();

  const onLoad = () => {
    setLoaded(true);
  };

  useEffect(() => {
    if (ref.current && ref.current.complete) {
      onLoad();
    }
  });

  return [refs, loaded, onLoad];
};
